<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalFilterClone"
        :title="i18nTitleModal"
        :in-editing="true"
        size="lg"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nTitleModal"
                    :operation="'copy'"
                />
                <b-row>
                    <b-table-simple
                        show-empty
                        small
                        responsive
                    >
                        <b-thead>
                            <b-tr>
                                <b-th>
                                    {{ i18nPos }}
                                </b-th>
                                <b-th>
                                    {{ i18nMix }}
                                </b-th>
                                <b-th>
                                    {{ i18nBusinessUnit }}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="wsi in data.items" :key="wsi.id">
                                <b-td>
                                    {{ wsi.work_schedule.point_of_sale.name }}
                                </b-td>
                                <b-td>
                                    {{ wsi.work_schedule.mix.name }}
                                </b-td>
                                <b-td>
                                    {{ wsi.work_schedule.business_unit.name }}
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="getI18n('COMMON', 'date')"
                            class="required label-pdv"
                            label-for="modal-start-date"
                            :invalid-feedback="getI18n('ERROR_CODES', 'invalid_date')"
                        >
                            <date-picker
                                id="modal-start-date"
                                :child-class="validationClass($v.modalCopy.date)"
                                :value.sync="$v.modalCopy.date.$model"
                                :min-date="new Date()"
                                :disabled="submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nUser"
                            class="required label-pdv"
                            label-for="modal-user"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_user')"
                        >
                            <multiSelectWithService
                                :id="'modal-user'"
                                ref="userMultiSelect"
                                v-model="modalCopy.user"
                                :v="$v.modalCopy.user_id"
                                :class="validationClass($v.modalCopy.user_id)"
                                :service="'users'"
                                :searchable="true"
                                :multiple="false"
                                :parameters="usersParameters"
                                @input="changeUserId"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div class="col text-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <add-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :title="getI18n('COMMON.copy')"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'

import swalFeedback from '@src/mixins/swalFeedback'

import WorkScheduleItemService from '@src/services/WorkScheduleItemService'

import validationMixin from '@src/mixins/validation'

import multiSelectWithService from '@src/components/multiSelectWithService'
import DatePicker from '@/src/components/DatePicker'
import FormModal from '@components/FormModal'
import filterVuetable from '@src/mixins/filterVuetable'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import AddButton from '@/src/components/AddButton'
import CancelButton from '@/src/components/CancelButton.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'
const i18nKey = 'WORKSCHEDULES'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        multiSelectWithService,
        DatePicker,
        AddButton,
        CancelButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
    ],
    props: {
        userId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            submitLoading: false,
            data: [],
            modalCopy: {
                date: null,
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modalCopy: {
            date: {
                required,
                minDate(value, model) {
                    if (Number(this.userId) === model.user_id) {
                        return value >= moment().startOf('day').add(1, 'days').toDate()
                    }

                    return value >= moment().startOf('day').toDate()
                },
            },
            user_id: {
                required,
                numeric,
            },
        },
    },

    computed: {
        i18nTitleModal() {
            return `${this.getI18n(i18nCommon, 'copy')} ${this.getI18n(i18nKey, 'TITLES.work_schedule')}`
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos_alias')
        },
        i18nMix() {
            return this.getI18n('MIXES', 'TITLES.mix')
        },
        i18nBusinessUnit() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit')
        },
    },

    methods: {
        changeUserId(e) {
            this.modalCopy.user_id = e ? e.id : null
            this.$v.modalCopy.user_id.$touch()
            this.clearResponseError('user', 'modalCopy')
        },

        showModal(data) {
            this.data = data
            this.modalCopy = {
                date: null,
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
            }
            this.submitLoading = false
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            Object.assign(this.modalCopy,
            {
                date: null,
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
            })
            this.resetValidation()
        },

        handleCancel() {
            this.handleClose(true)
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalCopy.$touch()
            if (!this.$v.modalCopy.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const data = {
                user_id: this.modalCopy.user_id,
                date: moment(this.modalCopy.date).format('YYYY-MM-DD'),
                work_schedule_id: this.data.items.map((wsi) => wsi.work_schedule_id),
            }

            const operation = WorkScheduleItemService.clone(data)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nTitleModal, 'created')
            } else if (statusCode === '422') {
                this.unsuccessfulOperation(createResponse.data.errors)
            } else {
                this.warningFeedback(this.i18nTitleModal, createResponse.data.message)
            }

            this.submitLoading = false
        },
    },
}
</script>
