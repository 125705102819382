<template>
    <div
        class="card card-pdv card-ws"
        :style="`
            width: calc(${expanded ? '28%' : '12%'} - 7px);
            background-color: ${backgroundColor};
        `"
    >
        <div class="card-body">
            <b-row v-if="busy" class="d-flex justify-content-between pl-1 pr-1 align-items-center">
                <b-skeleton :width="expanded ? '25%' : '50%'" />
            </b-row>
            <b-row v-else class="d-flex justify-content-between pl-1 pr-1 align-items-center">
                <label v-if="expanded">{{ formatDateShort(item.date) }} ({{ i18nDayOfWeek }})</label>
                <label v-else :title="i18nDayOfWeek">{{ formatDateShort(item.date) }}</label>
                <a
                    v-if="!expanded"
                    class="mb-1"
                    href="javascript: void(0);"
                    :title="i18nExpand"
                    @click="$emit('expandCard', item.date, week)"
                >
                    <i class="fe-maximize-2 icon-gray" />
                </a>
            </b-row>
            <b-row>
                <hr class="m-0" :style="`border: 1px solid ${borderColor}; width: 100%`">
                <b-skeleton-table
                    v-if="busy"
                    :rows="4"
                    :columns="1"
                    small
                    responsive
                    hide-header
                    style="height: 220px;"
                />
                <b-table-simple
                    v-else
                    class="m-0"
                    show-empty
                    small
                    responsive
                    style="height: 220px;"
                >
                    <b-tbody>
                        <b-tr v-for="wsi in item.items" :key="wsi.id">
                            <b-td v-if="expanded">
                                <p style="margin: 0; font-size: 14px;">
                                    {{ wsi.work_schedule.point_of_sale.name }}
                                </p>
                                <div :style="`display: flex; flex-direction: column; margin-top: 4px;`">
                                    <span style="font-size: 10px;">{{ formatMixName(wsi.work_schedule.mix) }} ({{ formatBusinessUnitName(wsi.work_schedule.business_unit) }})</span>
                                </div>
                            </b-td>
                            <b-td v-else :title="`${wsi.work_schedule.point_of_sale.name}: ${formatMixName(wsi.work_schedule.mix)} (${formatBusinessUnitName(wsi.work_schedule.business_unit)})`">
                                {{ wsi.work_schedule.point_of_sale.name }}
                            </b-td>
                            <b-td class="text-right align-middle">
                                <a
                                    v-if="isPreviousDate"
                                    href="javascript: void(0);"
                                    title="Responder Questionário"
                                    @click="$emit('answerQuestionnaire', wsi.date, wsi.work_schedule_id, wsi.work_schedule.point_of_sale_id)"
                                >
                                    <i class="fe-edit icon-black" />
                                </a>
                                <a
                                    v-if="isFutureDate"
                                    class="ml-2"
                                    href="javascript: void(0);"
                                    :title="i18nDelete"
                                    @click="$emit('deleteItem', wsi.date, wsi.work_schedule.point_of_sale.name, [wsi.work_schedule_id])"
                                >
                                    <i class="fe-x-circle icon-red" />
                                </a>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-row>
        </div>
        <b-row v-if="!isPreviousDate || item.items.length > 0">
            <hr class="mt-1 mb-0" :style="`border: 1px solid ${borderColor}; width: 100%`">
        </b-row>
        <div class="d-flex justify-content-end mt-1">
            <b-skeleton v-if="busy" :width="expanded ? '25%' : '50%'" />
            <template v-else>
                <a
                    v-if="!isPreviousDate"
                    href="javascript: void(0);"
                    :title="i18nNewWorkSchedule"
                    @click="$emit('addItem', item.date)"
                >
                    <i class="fe-plus-circle icon-black icon-size-16" />
                </a>
                <a
                    v-if="item.items.length > 0"
                    class="ml-2"
                    href="javascript: void(0);"
                    :title="i18nCopyWorkSchedule"
                    @click="$emit('copyItem', item)"
                >
                    <i class="fe-copy icon-black icon-size-16" />
                </a>
                <!-- <a
                    v-if="!isPreviousDate && item.items.length > 0"
                    class="ml-2"
                    href="javascript: void(0);"
                    :title="i18nUpdateWorkSchedule"
                    @click="$emit('onClick')"
                >
                    <i class="fe-edit icon-black icon-size-16" />
                </a> -->
                <a
                    v-if="isFutureDate && item.items.length > 0"
                    class="ml-2"
                    href="javascript: void(0);"
                    :title="i18nDeleteAll"
                    @click="$emit('deleteItem', item.date, null, item.items.map((wsi) => wsi.work_schedule_id))"
                >
                    <i class="fe-trash-2 icon-black icon-size-16" />
                </a>
            </template>
        </div>
    </div>
</template>

<script>
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import moment from 'moment';

const i18nKey = 'WORKSCHEDULES'
const i18nCommon = 'COMMON'

export default {
    mixins: [
        vuetableFormatters,
    ],
    props: {
        item: {
            type: Object,
            required: false,
            default: () => ({})
        },
        week: {
            type: Number,
            required: false,
            default: () => (0)
        },
        expanded: {
            type: Boolean,
            required: false,
        },
        busy: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon
        }
    },
    computed: {
        i18nWorkSchedule() {
            return this.getI18n(i18nKey, 'TITLES.work_schedule')
        },
        i18nWorkSchedules() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            })
        },
        i18nDelete() {
            return this.getI18n(i18nCommon, 'delete')
        },
        i18nDeleteAll() {
            return this.getI18n(i18nCommon, 'delete_all')
        },
        i18nExpand() {
            return this.getI18n(i18nCommon, 'expand')
        },
        i18nCopyWorkSchedule() {
            return `${this.getI18n(i18nCommon, 'copy')} ${this.i18nWorkSchedule}`
        },
        i18nNewWorkSchedule() {
            return `${this.getI18n(i18nCommon, 'add')} ${this.i18nWorkSchedule}`
        },
        i18nUpdateWorkSchedule() {
            return `${this.getI18n(i18nCommon, 'update')} ${this.i18nWorkSchedule}`
        },
        i18nDayOfWeek() {
            return moment(this.item.date, 'YYYY-MM-DD').locale('pt-br').format('dddd')
        },
        isPreviousDate() {
            return moment(this.item.date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))
        },
        isToday() {
            return moment(this.item.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))
        },
        isFutureDate() {
            return moment(this.item.date, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD'))
        },
        backgroundColor() {
            if (this.isPreviousDate) {
                return '#FDFAE7'
            }
            else if (this.isToday) {
                return '#E2F6FE'
            }
            else {
                return '#FFF'
            }
        },
        borderColor() {
            if (this.isPreviousDate) {
                return '#FAF0B7'
            }
            else if (this.isToday) {
                return '#D5E5FB'
            }
            else {
                return '#F3F4F5'
            }
        },
    },
    methods: {
        formatMixName(mix) {
            return mix ? mix.name : ''
        },
        formatBusinessUnitName(businessUnit) {
            return businessUnit ? businessUnit.name : ''
        },
    },
}
</script>
