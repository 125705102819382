<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nPos"
                            label-for="filter-pos"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-pos'"
                                ref="posMultiselect"
                                v-model="filterModal.pos"
                                :service="'pos'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nMix"
                            label-for="filter-mixes"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-mixes'"
                                ref="mixMultiselect"
                                v-model="filterModal.mixes"
                                :service="'mixes'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nFrequency"
                            label-for="filter-frequency"
                            class="label-pdv">
                            <treeselect
                                id="filter-frequency"
                                v-model="filterModal.frequency"
                                :multiple="true"
                                :options="workScheduleFrequencies"
                                :value-consists-of="valueConsistsOf"
                                :placeholder="i18nFrequency"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :title="i18nFilterStartInInfo"
                            :label="i18nWorkScheduleStartIn"
                            label-for="filter-started-at-date-time"
                            class="label-pdv">
                            <date-range-picker :title="i18nFilterStartInInfo" :value.sync="filterModal.start"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :title="i18nFilterEndInInfo"
                            :label="i18nWorkScheduleEndIn"
                            label-for="filter-end-at-date-time"
                            class="label-pdv">
                            <date-range-picker :title="i18nFilterEndInInfo" :value.sync="filterModal.end"/>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import Treeselect from '@riophae/vue-treeselect'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import DateRangePicker from '@/src/components/DateRangePicker'

const i18nKey = 'WORKSCHEDULES'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        SaveButton,
        ClearFilterButton,
        Treeselect,
        DateRangePicker
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        workScheduleFrequencies
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterModal: {},
            submitLoading: false,
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            valueConsistsOf: 'BRANCH_PRIORITY',
        }
    },
    validations: {},
    computed: {
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos')
        },
        i18nMix() {
            return this.getI18n('MIXES', 'TITLES.mix')
        },
        i18nFrequency() {
            return this.getI18n(i18nCommon, 'frequency')
        },
        i18nWorkScheduleStartIn() {
            return this.getI18n(i18nKey, 'MESSAGES.work_schedule_start_in')
        },
        i18nWorkScheduleEndIn() {
            return this.getI18n(i18nKey, 'MESSAGES.work_schedule_end_in')
        },
        i18nFilterStartInInfo() {
            return this.getI18n(i18nKey, 'MESSAGES.filter_start_in_info')
        },
        i18nFilterEndInInfo() {
            return this.getI18n(i18nKey, 'MESSAGES.filter_end_in_info')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                pos: [],
                mixes: [],
                frequency: [],
                start: null,
                end: null,
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
