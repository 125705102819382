import BaseService from '@src/services/BaseService'

class WorkScheduleService extends BaseService {
    constructor() {
        super('work-schedules')
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }
}
export default new WorkScheduleService()
